<template>
  <div>
    <v-container>
      <div>
        <h2>Transaction Details</h2>
      </div>
      <v-divider class="mb-5" />
      <transaction-summary :transaction="details" :loading="isLoading" />
    </v-container>
  </div>
</template>

<script>
import api from '@/api';
import { mapGetters } from 'vuex';
import CustomLabel from '@/components/customFormLabel/customLabel';
import TransactionSummary from '@/components/Transaction/TransactionSummary';
import moment from 'moment';
import ShortCutTag from '@/components/ShortCutTag.vue';
import { getStore } from '@/api/store';
import { get } from 'lodash';
import { getProfileIDbyUserID } from '@/api/Identities';

export default {
  name: 'TransactionDetails',
  components: { CustomLabel, ShortCutTag, TransactionSummary },
  mixins: [],
  props: {
    id: '',
  },
  data() {
    return {
      details: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['role', 'isAdmin', 'isMaster']),
    restfulURL() {
      const posConfigUrl = `${process.env.VUE_APP_POS_GATEWAY}`;
      if (this.id) return posConfigUrl + `/transactions/${this.id}`;
      else return null;
    },
    currency() {
      const currency = this.details?.amount?.currency?.toUpperCase();
      return `${currency}$`;
    },
    amount() {
      const value = this.details?.amount?.value?.$numberDecimal;
      return Math.abs(parseInt(value));
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    get,
    moment,
    async load() {
      this.isLoading = true;
      await this.getTransaction();
      await this.getStoreByTransactionDetails();
      await this.getProfileByTransactionDetails();
      this.isLoading = false;
    },
    async getTransaction() {
      this.details = (await api.get(this.restfulURL))?.data;
    },
    async getStoreByTransactionDetails() {
      const data = await getStore(api, this.details?.store);
      if (data) this.$set(this.details, 'store', data);
    },
    async getProfileByTransactionDetails() {
      const data = (await getProfileIDbyUserID(api, this.details.createdBy))?.[0];
      if (data) this.$set(this.details, 'createdBy', data);
    },
  },
};
</script>
