<template>
  <ValidationObserver ref="form" v-slot="{ invalid }">
    <v-container>
      <v-row>
        <v-col cols="12">
          <custom-label item-field-name="ClockIn Type" />
          <v-radio-group mandatory v-model="selectedClockInType" row hide-details="auto">
            <v-radio
              v-for="clockInType in clockInTypeOptions"
              :key="clockInType.value"
              class="mt-4"
              :label="clockInType.label"
              :value="clockInType.value"
              style="width: 40%"
            />
          </v-radio-group>
        </v-col>
        <v-col cols="12" md="6">
          <custom-label item-field-name="Staff" />
          <ValidationProvider v-slot="{ errors }" name="Staff" rules="required">
            <autocomplete
              label="Staff"
              :path="'toca/admin/Profiles'"
              :params="{
                limit: -1,
                sort: { name: 1 },
                filter: { status: ProfileStatus.accepted, role: { $ne: roles.client } },
              }"
              item-text="name"
              item-value="_id"
              v-model="model.staff"
              hide-details="auto"
              :error-messages="errors"
              :clearable="true"
              :has-items-slot="true"
            >
              <template v-slot:items="content">
                <span>{{
                  get(content, 'item.name', '') +
                  ' ' +
                  (get(content, 'item.phone', '')
                    ? displayPhone(get(content, 'item.phone', ''))
                    : '')
                }}</span>
              </template>
            </autocomplete>
          </ValidationProvider>
        </v-col>
        <v-col cols="12" md="6">
          <custom-label item-field-name="Store" />
          <ValidationProvider v-slot="{ errors }" name="Store" rules="required">
            <autocomplete
              v-model="model.store"
              label="Store"
              :path="'toca/admin/stores'"
              :params="{
                limit: -1,
                sort: { name: 1 },
                select: ['sid', 'name'],
              }"
              item-text="name"
              item-value="_id"
              hide-details="auto"
              :error-messages="errors"
              :clearable="true"
              :has-items-slot="true"
            >
              <template v-slot:items="content">
                {{ get(content, 'item.name', '') }}
              </template>
            </autocomplete>
          </ValidationProvider>
        </v-col>
        <v-col cols="12" md="6">
          <custom-label item-field-name="Date" />
          <ValidationProvider v-slot="{ errors }" name="Date" rules="required">
            <DatePickerWrapperInputMenu
              outlined
              v-model="clockInOutDate"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="12" md="6">
          <custom-label item-field-name="Time" />
          <ValidationProvider v-slot="{ errors }" name="Time" rules="required">
            <TimePickerWrapperInputMenu
              outlined
              v-model="clockInOutTime"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
        <!-- <v-col cols="6">
          <custom-label item-field-name="Status" />
          <v-row>
            <v-col> <v-checkbox label="Late"></v-checkbox></v-col>
            <v-col> <v-checkbox label="Early Leave"></v-checkbox></v-col>
          </v-row>
        </v-col> -->
      </v-row>
      <v-row>
        <v-col class="d-flex justify-end">
          <v-btn color="primary" :disabled="!model.staff || !model.store" @click="debounceSave">
            Save
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </ValidationObserver>
</template>

<script>
import api from '@/api';
import moment from 'moment';
import { crudCreate } from '@/api/crud';
import CustomLabel from '@/components/customFormLabel/customLabel';
import { ClockInType, RESTFUL } from '@/data/constants';
import DatePickerWrapperInputMenu from '@/components/DatePickerWrapperInputMenu';
import TimePickerWrapperInputMenu from '@/components/TimePickerWrapperInputMenu';
import { mapActions } from 'vuex';
import Autocomplete from '@/components/Autocomplete/Autocomplete';
import { displayPhone } from '@/services/filters';
import { debounce, get } from 'lodash';
import { ProfileStatus } from '@/api/auth/membership';
import { roles } from '@/data/constants';
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'ClockInDetails',
  components: {
    CustomLabel,
    Autocomplete,
    DatePickerWrapperInputMenu,
    TimePickerWrapperInputMenu,
    ValidationProvider,
  },
  data() {
    return {
      roles,
      ProfileStatus,
      clockInTypeOptions: [
        { label: 'Clock In', value: 'clockIn' },
        { label: 'Clock Out', value: 'clockOut' },
        { label: 'Clock Out For Lunch', value: 'clockOutForLunch' },
        { label: 'Clock In After Lunch', value: 'clockInAfterLunch' },
      ],
      selectedClockInType: ClockInType.clockIn,
      debounceSave: debounce(this.save, 500),
    };
  },
  props: {
    model: {
      type: Object,
      default: () => {
        return {
          date: moment().toISOString(),
        };
      },
    },
  },
  computed: {
    clockInOutDate: {
      get() {
        return moment(this.model.date).format('YYYY-MM-DD');
      },
      set(v) {
        const newDate = moment(v);
        const [year, month, date] = [newDate.year(), newDate.month(), newDate.date()];
        const currentDate = moment(this.model.date);
        currentDate.set({ year, month, date });
        this.model.date = currentDate.toDate();
      },
    },
    clockInOutTime: {
      get() {
        return moment(this.model.date).format('HH:mm');
      },
      set(v) {
        const currentDate = moment(this.model.date);
        const [hour, minute] = v.split(':');
        currentDate.set({ hour, minute, second: 0, millisecond: 0 });
        this.model.date = currentDate.toDate();
      },
    },
  },
  watch: {
    selectedClockInType: {
      immediate: true,
      async handler(v) {
        if (v === 'clockIn' || v === 'clockOut') {
          this.$set(this.model, 'clockInType', v);
          this.$set(this.model, 'isLunchBreak', false);
        } else {
          this.$set(this.model, 'isLunchBreak', true);
          if (v === 'clockOutForLunch') {
            this.$set(this.model, 'clockInType', 'clockOut');
          } else if (v === 'clockInAfterLunch') {
            this.$set(this.model, 'clockInType', 'clockIn');
          }
        }
      },
    },
  },
  methods: {
    ...mapActions('alert', ['updateAlertMessage']),
    get,
    displayPhone,
    moment,
    async save() {
      if (!(await this.$refs.form.validate())) {
        return;
      }
      try {
        await crudCreate(api, RESTFUL.clockInRecords.create, this.model);
        const msg = 'Record Created';
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: msg,
          type: 'success',
          color: 'success',
        });
        this.$emit('create', this.model);
      } catch (error) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: error,
          type: 'error',
          color: 'error',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
