var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ListView',{attrs:{"title":"Transaction","enable-select-items-per-page":"","search":_vm.search,"data":_vm.data,"page":_vm.page,"header":_vm.header,"is-fluid":true,"loading":_vm.isLoading},on:{"reload":_vm.reload,"navDetails":_vm.actionDetails,"update:limit":function($event){_vm.limit = $event},"update:search":function($event){_vm.search=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-btn',{attrs:{"text":""},on:{"click":_vm.debounceReload}},[_c('v-icon',[_vm._v("mdi-reload")])],1),_c('div',[_c('v-btn',{staticClass:"mx-4",attrs:{"disabled":_vm.availableForExportFunction,"loading":_vm.exportCSVLoadingRef},on:{"click":_vm.debounceExportSalesRecord}},[_vm._v(" Export Product sales records ")]),_c('v-btn',{attrs:{"disabled":_vm.availableForExportFunction,"loading":_vm.exportCSVLoadingRef},on:{"click":_vm.debounceExportTransactionRecord}},[_vm._v(" Export transaction records ")]),_c('div',{staticClass:"mx-4",staticStyle:{"color":"red","font-size":"12px"}},[_vm._v(" The export function is available from 21:00 to 09:00 to prevent system overload ")])],1)]},proxy:true},{key:"filter",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('autocomplete',{attrs:{"label":"Store","path":'toca/admin/stores',"params":{
            sort: { name: 1 },
            select: ['name', 'sid'],
          },"item-text":"name","item-value":"_id","clearable":true},model:{value:(_vm.filterStore),callback:function ($$v) {_vm.filterStore=$$v},expression:"filterStore"}}),_c('div',{staticStyle:{"color":"red","font-size":"12px","margin-top":"-25px"}},[_vm._v(" Please select one store for export report ")])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"label":"Transaction Type","items":_vm.POSTransactionTypesOptions,"item-text":"text","item-value":"value","outlined":"","clearable":""},model:{value:(_vm.posTransactionType),callback:function ($$v) {_vm.posTransactionType=$$v},expression:"posTransactionType"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"label":"Payment Method","items":_vm.POSpaymentMethodsOptions,"item-text":"text","item-value":"value","outlined":"","clearable":""},model:{value:(_vm.paymentMethod),callback:function ($$v) {_vm.paymentMethod=$$v},expression:"paymentMethod"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('DateRangePicker',{model:{value:(_vm.searchTime),callback:function ($$v) {_vm.searchTime=$$v},expression:"searchTime"}})],1)],1)]},proxy:true},{key:"item.createdAt",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.createdAt).format('YYYY-MM-DD HH:mm'))+" ")]}},{key:"item.mode",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.paymentMethodFormatter(item.mode))+" ")]}},{key:"item.amount",fn:function(ref){
          var item = ref.item;
return [_vm._v(" $ "+_vm._s(_vm.get(item, 'amount.value.$numberDecimal', ''))+" ")]}},{key:"item.details",fn:function(ref){
          var details = ref.item.details;
return [(details.serialNumber)?[_c('p',[_vm._v("Terminal")]),_c('p',[_vm._v(_vm._s(details.serialNumber))])]:_vm._e(),_c('p',[_vm._v(" "+_vm._s('Discount : ' + details.discount)+" ")])]}},{key:"item.orderType",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get(item, 'ref.orderType', '-'))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }