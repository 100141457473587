var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ListView',{attrs:{"title":"PurchaseOrders","enable-create":false,"search":_vm.search,"data":_vm.data,"header":_vm.header,"hidePagination":false,"page":_vm.page,"loading":_vm.isLoading},on:{"reload":_vm.reload,"create":_vm.actionCreate,"navDetails":_vm.actionDetails,"update:search":function($event){_vm.search=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.store",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.get(item, 'store.name', '-')))])]}},{key:"item.items",fn:function(ref){
var item = ref.item;
return [_c('div',_vm._l((item.items),function(subItem,index){return _c('div',{key:index},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"10px"}},[_c('v-img',{class:("" + (subItem.product.featuredImage || subItem.product.images[0] ? 'thumbnail' : '')),attrs:{"src":_vm.attnThumbHref(subItem.product.featuredImage || subItem.product.images[0], '64'),"height":"64","width":"64","max-height":"64","max-width":"64"},on:{"click":function($event){$event.stopPropagation();return (function () {
                if (subItem.product.featuredImage || subItem.product.images[0]) {
                  _vm.selectedPhysicalProduct = subItem.product;
                  _vm.displayImage = true;
                }
              }).apply(null, arguments)}}}),_c('div',[_c('div',[_vm._v("Quantity: "+_vm._s(subItem.qty))]),_c('div',[_vm._v("Product Name: "+_vm._s(_vm.parseLocalizedString(subItem.product.name).en))])])],1),_c('hr',{staticClass:"item-divider"})])}),0)]}},{key:"item.created_at",fn:function(ref){
              var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("moment")(item.created_at)))])]}},{key:"item.status",fn:function(ref){
              var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("status")(item.status.replace('_', ' '))))])]}},{key:"item.updateStatus",fn:function(ref){
              var item = ref.item;
return [(_vm.createOptions(item.status).length)?_c('div',{staticStyle:{"margin-top":"8px"}},[_c('v-select',{attrs:{"v-if":_vm.enableSelect(item.status),"disabled":!_vm.createOptions(item.status).length,"outlined":"","label":"Status","items":_vm.createOptions(item.status),"item-text":function (item) { return item.label; },"value":function (item) { return item.value; }},on:{"change":function (v) { return _vm.handleStatusChange(item, v); },"click":function($event){$event.stopPropagation();}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})],1):_vm._e()]}}])},[_c('CarouselDialog',{attrs:{"images":_vm.selectedImages},model:{value:(_vm.displayImage),callback:function ($$v) {_vm.displayImage=$$v},expression:"displayImage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }