/**
 * @param {AxiosInstance} api
 * @param {string} [search]
 * @return {Promise<string[]>}
 */
export async function listClockInRecords(api, options) {
  return (await api.get('toca/admin/clockInRecords/', { params: options })).data;
}

/**
 * @param {AxiosInstance} api
 * @return {Promise<void>}
 */
export async function createClockInRecord(api, data) {
  return (await api.post(`toca/admin/clockInRecords`, data)).data;
}

/**
 * @param {AxiosInstance} api
 * @param {string} id
 * @return {Promise<void>}
 */
export async function updateClockInRecord(api, staffId, data) {
  return (await api.patch(`toca/admin/clockInRecord/${staffId}`, data)).data;
}

/**
 * @param {AxiosInstance} api 
 * @param {string} id 
 * @param {*} options 
 * @return {Promise<void>}
 */
export async function readClockInRecord(api, id, options) {
  return (await api.get(`toca/admin/clockInRecord/read/${id}`, { params: options })).data
}

/**
 * 
 * @param {AxiosInstance} api 
 * @param {string} staffId 
 * @param {*} options 
 * @returns {Promise<void>}
 */
export async function deleteClockInRecord(api, staffId, options) {
  return (await api.delete(`toca/admin/clockInRecord/delete/${staffId}`, {params: options})).data
}

/**
 * 
 * @param {AxiosInstance} api 
 * @param {string} ids 
 * @returns {Promise<boolean>}
 */
export async function deleteClockInRecordInBulk(api, ids) {
  return (await api.delete(`toca/admin/clockInRecord/${ids}`)).data
}
