var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('v-card',[_c('v-card-title',{staticClass:"font-weight-bold"},[_vm._v("Transaction")]),_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":12,"md":6}},[(_vm.transaction && _vm.transaction.claimCreatedAt)?[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.transaction.claimCreatedAt,'YYYY/MM/DD hh:mm:ss'))+" ")]:(_vm.transaction && _vm.transaction.createdAt)?[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.transaction.createdAt,'YYYY/MM/DD hh:mm:ss'))+" ")]:[_vm._v(" - ")]],2),_c('v-col',{staticClass:"d-flex",attrs:{"cols":12,"md":6}},[_vm._v(" Store: "),(_vm.transaction && _vm.transaction.store)?_c('StoreNameTag',{staticClass:"ml-1",attrs:{"store":_vm.transaction && _vm.transaction.store}}):_vm._e()],1),_c('v-col',{staticClass:"text-capitalize",attrs:{"cols":12,"md":6}},[_vm._v(" Type: "+_vm._s(_vm.transaction && _vm.transaction.transactionType)+" ")]),((_vm.transaction && _vm.transaction.transactionType) === _vm.POSTransactionType.expense)?_c('v-col',{attrs:{"cols":12,"md":6}},[_vm._v("Expense Type: "+_vm._s(_vm.transaction && _vm.transaction.expenseType))]):_vm._e(),_c('v-col',{attrs:{"cols":12,"md":6}},[_vm._v("Amount: "+_vm._s(_vm.currency)+" "+_vm._s(_vm._f("toThousandFilter")(_vm.amount)))]),_c('v-col',{attrs:{"cols":12,"md":6}},[_vm._v("Remarks: "+_vm._s((_vm.transaction && _vm.transaction.remarks) || '-'))]),_c('v-col',{attrs:{"cols":12,"md":6}},[_vm._v(" Status: "+_vm._s(_vm._f("status")(_vm.transaction && _vm.transaction.status))+" ")]),_c('v-col',{staticClass:"d-flex",attrs:{"cols":12,"md":6}},[_vm._v(" Created By: "),(_vm.transaction && _vm.transaction.createdBy)?_c('ProfileNameTag',{staticClass:"ml-1",attrs:{"profile":_vm.transaction.createdBy}}):_vm._e()],1)],1),_c('v-row',{staticStyle:{"max-width":"100%"}},[_c('v-col',{attrs:{"cols":12}},[_vm._v("Related Documents (Images)")]),_c('v-col',{attrs:{"cols":12}},[(
            _vm.transaction &&
            _vm.transaction.details &&
            _vm.transaction.details.attachments &&
            _vm.transaction.details.attachments.length
          )?[_c('v-carousel',_vm._l((_vm.transaction &&
              _vm.transaction.details &&
              _vm.transaction.details.attachments),function(image,i){return _c('v-carousel-item',{key:i,attrs:{"src":_vm.attnThumbHref(image, 'hd'),"contain":""}})}),1)]:[_c('small',[_vm._v("(No images)")])]],2)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }