<template>
  <ListView
    title="Store"
    @reload="reload"
    @create="actionCreate"
    @navDetails="actionDetails"
    :enable-create="checkRole"
    :page.sync="page"
    :search.sync="search"
    :data="data"
    :header="header"
    :loading="isLoading"
  >
    <template v-slot:item.contact="{ item }"> {{ displayPhone(item.contact) || '-' }} </template>
  </ListView>
</template>

<script>
import ListView from '@/components/ListView';
import { roles } from '@/data/constants';
import { mapGetters } from 'vuex';
import { getStores } from '@/api/store';
import api from '@/api/';
import { crudList } from '@/api/crud';
import { RESTFUL } from '@/data/constants';
import listViewPageMixin from '@/services/listViewPageMixin';
import { displayPhone } from '@/services/filters';

export default {
  name: 'Store',
  data() {
    return {
      header: [
        // { text: 'SID', value: 'sid' },
        { text: 'Name', value: 'name' },
        { text: 'Phone', value: 'contact' },
        { text: 'Address', value: 'location' },
      ],
      data: [],
      search: '',
    };
  },
  mixins: [listViewPageMixin],
  computed: {
    ...mapGetters('auth', ['role', 'isAdmin']),

    checkRole() {
      return this.role !== roles.master;
    },
    restfulURL() {
      return RESTFUL.stores.list;
    },
  },

  methods: {
    // async reload() {
    //   this.data = await (this.isMaster
    //     ? crudList(api, RESTFUL.stores.list)
    //     : crudList(api, RESTFUL.stores.list));
    //   this.data?.sort((a, b) => a?.tableNo - b?.tableNo);
    // },
    // used in mixin
    displayPhone,
    actionCreate() {
      this.$router.push({ name: 'store', params: { id: 'new' } });
    },
    actionDetails(item) {
      this.$router.push({ name: 'store', params: { id: item?._id } });
    },
  },
};
</script>
