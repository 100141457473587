<template>
  <v-container>
    <div class="d-flex justify-space-between mb-2 px-3">
      <h2>{{ title }}</h2>
      <v-btn color="danger" @click="promptDelete"> Delete </v-btn>
    </div>
    <v-divider class="mb-5 mx-3" />
    <v-card class="pa-3 mx-3">
      <ValidationObserver ref="form" v-slot="{ invalid }">
        <v-row>
          <v-col class="d-flex" cols="12">
            <v-spacer />
            <v-btn color="primary" :disabled="invalid" @click.stop="debounceUpdate">Update</v-btn>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider v-slot="{ errors }" name="Staff">
              <v-autocomplete
                v-model="model.staff"
                label="Staff"
                :items="cashierProfiles"
                item-text="name"
                item-value="_id"
                outlined
                flat
                return-object
                disabled
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="model.store" label="Store" outlined flat disabled />
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider v-slot="{ errors }" name="Date">
              <v-text-field
                label="Date"
                v-model="model.date"
                prepend-inner-icon="mdi-calendar"
                disabled
                outlined
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-divider class="my-5"></v-divider>
        <v-row>
          <v-col cols="12" class="d-flex justify-space-between align-center">
            <custom-label item-field-name="Clock-in Time" />
            <v-btn
              color="primary"
              @click="() => model && model.clockIn.push({ _id: randomOid(), time: null })"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider v-slot="{ errors }">
              <TimePickerWrapperInputMenu
                :value="clockInTime && clockInTime[0]"
                @input="updateTimeValue($event, 'clockIn', 0)"
                outlined
                :error-messages="errors"
              >
                <template v-slot:append-outer>
                  <v-btn
                    icon
                    text
                    elevation="0"
                    @click="
                      removeTimeValue(
                        'clockIn',
                        model && model.clockIn && model.clockIn[0] && model.clockIn[0]._id,
                      )
                    "
                  >
                    <v-icon color="primary">mdi-minus</v-icon>
                  </v-btn>
                </template>
              </TimePickerWrapperInputMenu>
              <small>{{ computedMetadataText(model && model.clockIn && model.clockIn[0]) }}</small>
            </ValidationProvider>
          </v-col>
          <v-col
            v-for="(x, index) in model && model.clockIn && model.clockIn.slice(1)"
            cols="12"
            md="6"
            :key="index"
          >
            <ValidationProvider v-slot="{ errors }">
              <TimePickerWrapperInputMenu
                :value="clockInTime && clockInTime[index + 1]"
                @input="updateTimeValue($event, 'clockIn', index + 1)"
                outlined
                :error-messages="errors"
              >
                <template v-slot:append-outer>
                  <v-btn
                    icon
                    text
                    elevation="0"
                    @click="
                      removeTimeValue(
                        'clockIn',
                        model &&
                          model.clockIn &&
                          model.clockIn[index + 1] &&
                          model.clockIn[index + 1]._id,
                      )
                    "
                  >
                    <v-icon color="primary">mdi-minus</v-icon>
                  </v-btn>
                </template>
              </TimePickerWrapperInputMenu>
              <small>
                {{ computedMetadataText(model && model.clockIn && model.clockIn[index + 1]) }}
              </small>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-divider class="my-5"></v-divider>
        <v-row>
          <v-col cols="12" class="d-flex justify-space-between align-center">
            <custom-label item-field-name="Clock-out Time" />
            <v-btn
              color="primary"
              @click="() => model && model.clockOut.push({ _id: randomOid(), time: null })"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider v-slot="{ errors }">
              <TimePickerWrapperInputMenu
                :value="clockOutTime && clockOutTime[0]"
                @input="updateTimeValue($event, 'clockOut', 0)"
                outlined
                :error-messages="errors"
              >
                <template v-slot:append-outer>
                  <v-btn
                    icon
                    text
                    elevation="0"
                    @click="
                      removeTimeValue(
                        'clockOut',
                        model && model.clockOut && model.clockOut[0] && model.clockOut[0]._id,
                      )
                    "
                  >
                    <v-icon color="primary">mdi-minus</v-icon>
                  </v-btn>
                </template>
              </TimePickerWrapperInputMenu>
              <small>
                {{ computedMetadataText(model && model.clockOut && model.clockOut[0]) }}
              </small>
            </ValidationProvider>
          </v-col>
          <v-col
            v-for="(x, index) in model && model.clockOut && model.clockOut.slice(1)"
            cols="12"
            md="6"
            :key="index"
          >
            <ValidationProvider v-slot="{ errors }">
              <TimePickerWrapperInputMenu
                :value="clockOutTime && clockOutTime[index + 1]"
                @input="updateTimeValue($event, 'clockOut', index + 1)"
                outlined
                :error-messages="errors"
              >
                <template v-slot:append-outer>
                  <v-btn
                    icon
                    text
                    elevation="0"
                    @click="
                      removeTimeValue(
                        'clockOut',
                        model &&
                          model.clockOut &&
                          model.clockOut[index + 1] &&
                          model.clockOut[index + 1]._id,
                      )
                    "
                  >
                    <v-icon color="primary">mdi-minus</v-icon>
                  </v-btn>
                </template>
              </TimePickerWrapperInputMenu>
              <small>
                {{ computedMetadataText(model && model.clockOut && model.clockOut[index + 1]) }}
              </small>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-divider class="my-5"></v-divider>
        <v-row>
          <v-col cols="12">
            <custom-label item-field-name="Clock-out Time For Lunch" />
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider v-slot="{ errors }">
              <TimePickerWrapperInputMenu
                :value="clockOutTimeForLunch"
                @input="updateTimeValue($event, 'clockOutForLunch', 0)"
                outlined
                :error-messages="errors"
              >
                <template v-slot:append-outer>
                  <v-btn
                    icon
                    text
                    elevation="0"
                    @click="
                      removeTimeValue(
                        'clockOutForLunch',
                        model &&
                          model.clockOutForLunch &&
                          model.clockOutForLunch[0] &&
                          model.clockOutForLunch[0]._id,
                      )
                    "
                  >
                    <v-icon color="primary">mdi-minus</v-icon>
                  </v-btn>
                </template>
              </TimePickerWrapperInputMenu>
              <small>
                {{
                  computedMetadataText(model && model.clockOutForLunch && model.clockOutForLunch[0])
                }}
              </small>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-divider class="my-5"></v-divider>
        <v-row>
          <v-col cols="12">
            <custom-label item-field-name="Clock-in Time After Lunch" />
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider v-slot="{ errors }">
              <TimePickerWrapperInputMenu
                :value="clockInTimeAfterLunch"
                @input="updateTimeValue($event, 'clockInAfterLunch', 0)"
                outlined
                :error-messages="errors"
              >
                <template v-slot:append-outer>
                  <v-btn
                    icon
                    text
                    elevation="0"
                    @click="
                      removeTimeValue(
                        'clockInAfterLunch',
                        model &&
                          model.clockInAfterLunch &&
                          model.clockInAfterLunch[0] &&
                          model.clockInAfterLunch[0]._id,
                      )
                    "
                  >
                    <v-icon color="primary">mdi-minus</v-icon>
                  </v-btn>
                </template>
              </TimePickerWrapperInputMenu>
              <small>
                {{
                  computedMetadataText(
                    model && model.clockInAfterLunch && model.clockInAfterLunch[0],
                  )
                }}
              </small>
            </ValidationProvider>
          </v-col>
        </v-row>
      </ValidationObserver>
    </v-card>
  </v-container>
</template>

<script>
import api from '@/api';
import { crudList } from '@/api/crud';
import { getOid } from '@/services/utils';
import { RESTFUL } from '@/data/constants';
import {
  readClockInRecord,
  updateClockInRecord,
  deleteClockInRecord,
  deleteClockInRecordInBulk,
} from '@/api/clockInRecords';
import { getMatchedStores } from '@/api/storeUser';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import TimePickerWrapperInputMenu from '@/components/TimePickerWrapperInputMenu.vue';
import DatePickerWrapperInputMenu from '@/components/DatePickerWrapperInputMenu';
import CustomLabel from '@/components/customFormLabel/customLabel';
import moment from 'moment';
import { randomOid } from '@/api/demo';
import { debounce } from 'lodash';

export default {
  name: 'ClockInRecordDetails',
  components: {
    ValidationProvider,
    ValidationObserver,
    CustomLabel,
    DatePickerWrapperInputMenu,
    TimePickerWrapperInputMenu,
    TimePickerWrapperInputMenu,
  },
  props: {
    staff: null,
    date: null,
    store: null,
  },
  data() {
    return {
      model: {},
      cashierProfiles: [],
      clockInTimeDialog: false,
      clockOutTimeDialog: false,
      clockInAfterLunchTimeDialog: false,
      clockOutForLunchTimeDialog: false,
      newClockInTime: null,
      newClockOutTime: null,
      updatedClockInRecords: [],
      deletedClockInRecords: [],
      debounceUpdate: debounce(this.update, 500),
    };
  },
  computed: {
    title() {
      return 'Clock-in/out Record Details';
    },
    clockInTime() {
      return this.model.clockIn?.map(x =>
        x?.time ? moment(this.convertStringToTime(this.model.date, x?.time)).format('HH:mm') : null,
      );
    },
    clockOutTime() {
      return this.model.clockOut?.map(x =>
        x?.time ? moment(this.convertStringToTime(this.model.date, x?.time)).format('HH:mm') : null,
      );
    },
    clockOutTimeForLunch() {
      const time = this.model?.clockOutForLunch?.[0]?.time;
      return time ? moment(this.convertStringToTime(this.model.date, time)).format('HH:mm') : null;
    },
    clockInTimeAfterLunch() {
      const time = this.model?.clockInAfterLunch?.[0]?.time;
      return time ? moment(this.convertStringToTime(this.model.date, time)).format('HH:mm') : null;
    },
  },
  async beforeMount() {
    await this.load();
  },
  methods: {
    moment,
    randomOid,
    async load() {
      await this.getStaffProfiles();
      await this.getClockInRecord();
      await this.mapCashierStores();
    },
    async getStaffProfiles() {
      try {
        this.cashierProfiles = (
          await crudList(api, RESTFUL.profile.list, {
            filter: { role: 'toca.cashier' },
            select: ['name', 'sid'],
            limit: -1,
            sort: { name: 1 },
          })
        )?.docs;
      } catch (error) {
        this.$store.dispatch('alert/updateAlertMessage', {
          msg: error.message,
          type: 'error',
          color: 'error',
        });
      }
    },
    async getClockInRecord() {
      try {
        this.model = await readClockInRecord(api, '', {
          staff: this.staff,
          store: this.store,
          date: this.date,
        });
      } catch (error) {
        this.$store.dispatch('alert/updateAlertMessage', {
          msg: error.message,
          type: 'error',
          color: 'error',
        });
      }
    },
    async mapCashierStores() {
      try {
        const fetchedData = await getMatchedStores(api, {
          profileIds: [getOid(this.model.staff)],
        });
        this.$set(
          this.model,
          'store',
          fetchedData[getOid(this.model.staff)]?.map(s => s?.name)?.join(', '),
        );
      } catch (error) {
        this.$store.dispatch('alert/updateAlertMessage', {
          msg: error.message,
          type: 'error',
          color: 'error',
        });
      }
    },
    async promptDelete() {
      if (!confirm('Do you confrm to delete?')) return;
      try {
        // delete
        const { staff } = this.model;
        await deleteClockInRecord(api, staff, { date: this.model.date });
        this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Deleted',
          type: 'success',
          color: 'success',
        });
        // go back to list view
        this.$router.replace({ name: 'ClockInRecords' });
      } catch (error) {
        this.$store.dispatch('alert/updateAlertMessage', {
          msg: error.message,
          type: 'error',
          color: 'error',
        });
      }
    },
    async update() {
      try {
        // update
        if (this.updatedClockInRecords?.length > 0) {
          await updateClockInRecord(
            api,
            this.updatedClockInRecords?.map(x => getOid(x)),
            { payload: this.updatedClockInRecords },
          );
        }

        // delete
        if (this.deletedClockInRecords?.length > 0) {
          await deleteClockInRecordInBulk(
            api,
            this.deletedClockInRecords?.map(x => getOid(x)),
          );
        }

        this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Updated',
          type: 'success',
          color: 'success',
        });

        // reset payload
        this.updatedClockInRecords = [];
        this.deletedClockInRecords = [];
        return;
      } catch (error) {
        this.$store.dispatch('alert/updateAlertMessage', {
          msg: error.message,
          type: 'error',
          color: 'error',
        });
      }
    },
    convertStringToTime(dateString, timeString) {
      const combinedString = `${dateString}T${timeString}:00`;
      return new Date(combinedString);
    },
    computedAppendPayload(key) {
      const out = {
        staff: this.staff,
      };
      if (key === 'clockIn') {
        out.clockInType = 'clockIn';
        out.isLunchBreak = false;
      } else if (key === 'clockOut') {
        out.clockInType = 'clockOut';
        out.isLunchBreak = false;
      } else if (key === 'clockInAfterLunch') {
        out.clockInType = 'clockIn';
        out.isLunchBreak = true;
      } else if (key === 'clockOutForLunch') {
        out.clockInType = 'clockOut';
        out.isLunchBreak = true;
      }
      return out;
    },
    updateTimeValue(value, key, index) {
      const clockInRecordId = this.model?.[key]?.[index]?._id
        ? this.model?.[key]?.[index]?._id
        : randomOid();

      if (this.model?.[key]?.[index]) this.model[key][index].time = value;
      else this.model[key] = [{ _id: randomOid(), time: value }];

      const ids = this.updatedClockInRecords?.map(x => x?._id);
      if (ids?.includes(clockInRecordId)) {
        this.updatedClockInRecords = this.updatedClockInRecords?.map(x => {
          if (x?._id === clockInRecordId) {
            return {
              _id: clockInRecordId,
              date: moment(this.convertStringToTime(this.model.date, value)).toDate(),
              ...this.computedAppendPayload(key),
            };
          } else {
            return x;
          }
        });
      } else {
        this.updatedClockInRecords.push({
          _id: clockInRecordId,
          date: moment(this.convertStringToTime(this.model.date, value)).toDate(),
          ...this.computedAppendPayload(key),
        });
      }
    },
    removeTimeValue(key, id) {
      this.model[key] = this.model?.[key]?.filter(x => x?._id !== id);
      this.deletedClockInRecords.push(id);
    },
    computedMetadataText(clockInRecord) {
      const { created_by, updated_at, updated_by } = clockInRecord || {};
      if (updated_at)
        return `Updated ${
          updated_by ? 'by ' + updated_by : created_by ? 'by ' + created_by : ''
        } at ${moment(updated_at).format('YYYY-MM-DD HH:mm:ss')}`;
      else return '';
    },
  },
};
</script>

<style scoped></style>
