<template>
  <ListView
    title="PurchaseOrders"
    @reload="reload"
    @create="actionCreate"
    @navDetails="actionDetails"
    :enable-create="false"
    :search.sync="search"
    :data="data"
    :header="header"
    :hidePagination="false"
    :page.sync="page"
    :loading="isLoading"
  >
    <template v-slot:item.store="{ item }">
      {{ getStoreName(item.store) }}
    </template>
    <template v-slot:item.items="{ item }">
      <div>
        <div v-for="(subItem, index) in item.items" :key="index">
          <div class="d-flex align-center" style="gap: 10px">
            <v-img
              :src="attnThumbHref(subItem.product.featuredImage || subItem.product.images[0], '64')"
              height="64"
              width="64"
              max-height="64"
              max-width="64"
              :class="`${
                subItem.product.featuredImage || subItem.product.images[0] ? 'thumbnail' : ''
              }`"
              @click.stop="
                () => {
                  if (subItem.product.featuredImage || subItem.product.images[0]) {
                    selectedPhysicalProduct = subItem.product;
                    displayImage = true;
                  }
                }
              "
            />
            <div>
              <div>Quantity: {{ subItem.qty }}</div>
              <div>Product Name: {{ parseLocalizedString(subItem.product.name).en }}</div>
            </div>
          </div>
          <hr class="item-divider" />
        </div>
      </div>
    </template>
    <template v-slot:item.created_at="{ item }">
      <div>{{ item.created_at | moment }}</div>
    </template>
    <template v-slot:item.status="{ item }">
      <div>{{ item.status.replace('_', ' ') | status }}</div>
    </template>
    <template v-slot:item.store="{ item }">
      <div>{{ get(item, 'store.name', '-') }}</div>
    </template>
    <template v-slot:item.updateStatus="{ item }">
      <div style="margin-top: 8px" v-if="createOptions(item.status).length">
        <v-select
          :v-if="enableSelect(item.status)"
          :disabled="!createOptions(item.status).length"
          outlined
          label="Status"
          v-model="item.status"
          :items="createOptions(item.status)"
          :item-text="item => item.label"
          :value="item => item.value"
          @change="v => handleStatusChange(item, v)"
          @click.stop
        />
      </div>
    </template>
    <CarouselDialog v-model="displayImage" :images="selectedImages" />
  </ListView>
</template>

<script>
import ListView from '@/components/ListView';
import { roles } from '@/data/constants';
import { mapGetters } from 'vuex';
import { getStores } from '@/api/store';
import { patchPurchaseOrder } from '@/api/purchase-orders';
import api from '@/api/';
import { crudList } from '@/api/crud';
import { RESTFUL } from '@/data/constants';
import { parseLocalizedString } from '@/services/utils';
import listViewPageMixin from '@/services/listViewPageMixin';
import moment from 'moment';
import { attnThumbHref } from '@/services/filters';
import CarouselDialog from '@/components/CarouselDialog.vue';
import { get } from 'lodash';

export default {
  name: 'PurchaseOrder',
  components: { CarouselDialog, CarouselDialog },
  data() {
    return {
      header: [
        { text: 'Date', value: 'created_at' },
        { text: 'Store', value: 'store' },
        { text: 'Items', value: 'items' },
        { text: 'Status', value: 'status' },
        { text: '', value: 'updateStatus' },
      ],
      data: [],
      search: '',
      storeList: {},
      displayImage: false,
      selectedPhysicalProduct: null,
      populate: [{ path: 'store', model: 'Store' }],
    };
  },
  mixins: [listViewPageMixin],
  computed: {
    ...mapGetters('auth', ['role', 'isAdmin']),

    checkRole() {
      return this.role !== roles.master;
    },
    restfulURL() {
      return RESTFUL.purchaseOrders.list;
    },
    selectedImages() {
      const list = this.selectedPhysicalProduct?.featuredImage
        ? [this.selectedPhysicalProduct?.featuredImage]
        : [];
      return list.concat(this.selectedPhysicalProduct?.images || []);
    },
  },
  mounted() {
    this.getStoreList();
  },
  methods: {
    attnThumbHref,
    parseLocalizedString,
    get,
    actionCreate() {
      // this.$router.push({ name: 'supplier', params: { id: 'new' } });
    },
    actionDetails(item) {
      this.$router.push({ name: 'purchase-order', params: { id: item?._id } });
    },
    enableSelect(status) {
      let enable = true;
      console.log(status, 'status here');
      if (status === 'in_progress' || status === 'received') {
        enable = false;
      }
      return true;
    },
    createOptions(status) {
      let prepStatusesOptions = [];
      if (status === 'pending') {
        prepStatusesOptions.push({ label: 'In Progress', value: 'in_progress' });
        prepStatusesOptions.push({ label: 'Reject', value: 'rejected' });
      }
      if (status === 'received') {
      }
      if (status === 'canceled') {
      }
      return prepStatusesOptions;
    },
    async handleStatusChange(item, status) {
      console.log(item._id, status, 'item here');
      await patchPurchaseOrder(api, item._id, {
        status: status,
      });
      await this.$store.dispatch('alert/updateAlertMessage', {
        msg: 'Updated Status',
        type: 'success',
        color: 'success',
      });
    },
    async getStoreList() {
      this.storeList = await getStores(api, { limit: -1 });
    },
    getStoreName(storeId) {
      const store = this.storeList?.docs?.find(x => x?._id === storeId);
      return store ? store?.name : 'Unknown';
    },
  },
};
</script>

<style>
.item-container {
  padding: 8px 0;
}

.item-divider {
  border: none;
  border-bottom: 1px solid #ccc;
  margin: 8px 0;
}

.thumbnail {
  cursor: zoom-in;
}
</style>
