var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"d-flex justify-space-between mb-2 px-3"},[_c('h2',[_vm._v(_vm._s(_vm.title))]),_c('v-btn',{attrs:{"color":"danger"},on:{"click":_vm.promptDelete}},[_vm._v(" Delete ")])],1),_c('v-divider',{staticClass:"mb-5 mx-3"}),_c('v-card',{staticClass:"pa-3 mx-3"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":function($event){$event.stopPropagation();return _vm.debounceUpdate.apply(null, arguments)}}},[_vm._v("Update")])],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Staff"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Staff","items":_vm.cashierProfiles,"item-text":"name","item-value":"_id","outlined":"","flat":"","return-object":"","disabled":"","error-messages":errors},model:{value:(_vm.model.staff),callback:function ($$v) {_vm.$set(_vm.model, "staff", $$v)},expression:"model.staff"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Store","outlined":"","flat":"","disabled":""},model:{value:(_vm.model.store),callback:function ($$v) {_vm.$set(_vm.model, "store", $$v)},expression:"model.store"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Date","prepend-inner-icon":"mdi-calendar","disabled":"","outlined":"","error-messages":errors},model:{value:(_vm.model.date),callback:function ($$v) {_vm.$set(_vm.model, "date", $$v)},expression:"model.date"}})]}}],null,true)})],1)],1),_c('v-divider',{staticClass:"my-5"}),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-space-between align-center",attrs:{"cols":"12"}},[_c('custom-label',{attrs:{"item-field-name":"Clock-in Time"}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function () { return _vm.model && _vm.model.clockIn.push({ _id: _vm.randomOid(), time: null }); }}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TimePickerWrapperInputMenu',{attrs:{"value":_vm.clockInTime && _vm.clockInTime[0],"outlined":"","error-messages":errors},on:{"input":function($event){return _vm.updateTimeValue($event, 'clockIn', 0)}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"icon":"","text":"","elevation":"0"},on:{"click":function($event){return _vm.removeTimeValue(
                      'clockIn',
                      _vm.model && _vm.model.clockIn && _vm.model.clockIn[0] && _vm.model.clockIn[0]._id
                    )}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-minus")])],1)]},proxy:true}],null,true)}),_c('small',[_vm._v(_vm._s(_vm.computedMetadataText(_vm.model && _vm.model.clockIn && _vm.model.clockIn[0])))])]}}],null,true)})],1),_vm._l((_vm.model && _vm.model.clockIn && _vm.model.clockIn.slice(1)),function(x,index){return _c('v-col',{key:index,attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('TimePickerWrapperInputMenu',{attrs:{"value":_vm.clockInTime && _vm.clockInTime[index + 1],"outlined":"","error-messages":errors},on:{"input":function($event){return _vm.updateTimeValue($event, 'clockIn', index + 1)}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"icon":"","text":"","elevation":"0"},on:{"click":function($event){return _vm.removeTimeValue(
                      'clockIn',
                      _vm.model &&
                        _vm.model.clockIn &&
                        _vm.model.clockIn[index + 1] &&
                        _vm.model.clockIn[index + 1]._id
                    )}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-minus")])],1)]},proxy:true}],null,true)}),_c('small',[_vm._v(" "+_vm._s(_vm.computedMetadataText(_vm.model && _vm.model.clockIn && _vm.model.clockIn[index + 1]))+" ")])]}}],null,true)})],1)})],2),_c('v-divider',{staticClass:"my-5"}),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-space-between align-center",attrs:{"cols":"12"}},[_c('custom-label',{attrs:{"item-field-name":"Clock-out Time"}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function () { return _vm.model && _vm.model.clockOut.push({ _id: _vm.randomOid(), time: null }); }}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('TimePickerWrapperInputMenu',{attrs:{"value":_vm.clockOutTime && _vm.clockOutTime[0],"outlined":"","error-messages":errors},on:{"input":function($event){return _vm.updateTimeValue($event, 'clockOut', 0)}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"icon":"","text":"","elevation":"0"},on:{"click":function($event){return _vm.removeTimeValue(
                      'clockOut',
                      _vm.model && _vm.model.clockOut && _vm.model.clockOut[0] && _vm.model.clockOut[0]._id
                    )}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-minus")])],1)]},proxy:true}],null,true)}),_c('small',[_vm._v(" "+_vm._s(_vm.computedMetadataText(_vm.model && _vm.model.clockOut && _vm.model.clockOut[0]))+" ")])]}}],null,true)})],1),_vm._l((_vm.model && _vm.model.clockOut && _vm.model.clockOut.slice(1)),function(x,index){return _c('v-col',{key:index,attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('TimePickerWrapperInputMenu',{attrs:{"value":_vm.clockOutTime && _vm.clockOutTime[index + 1],"outlined":"","error-messages":errors},on:{"input":function($event){return _vm.updateTimeValue($event, 'clockOut', index + 1)}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"icon":"","text":"","elevation":"0"},on:{"click":function($event){return _vm.removeTimeValue(
                      'clockOut',
                      _vm.model &&
                        _vm.model.clockOut &&
                        _vm.model.clockOut[index + 1] &&
                        _vm.model.clockOut[index + 1]._id
                    )}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-minus")])],1)]},proxy:true}],null,true)}),_c('small',[_vm._v(" "+_vm._s(_vm.computedMetadataText(_vm.model && _vm.model.clockOut && _vm.model.clockOut[index + 1]))+" ")])]}}],null,true)})],1)})],2),_c('v-divider',{staticClass:"my-5"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('custom-label',{attrs:{"item-field-name":"Clock-out Time For Lunch"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('TimePickerWrapperInputMenu',{attrs:{"value":_vm.clockOutTimeForLunch,"outlined":"","error-messages":errors},on:{"input":function($event){return _vm.updateTimeValue($event, 'clockOutForLunch', 0)}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"icon":"","text":"","elevation":"0"},on:{"click":function($event){return _vm.removeTimeValue(
                      'clockOutForLunch',
                      _vm.model &&
                        _vm.model.clockOutForLunch &&
                        _vm.model.clockOutForLunch[0] &&
                        _vm.model.clockOutForLunch[0]._id
                    )}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-minus")])],1)]},proxy:true}],null,true)}),_c('small',[_vm._v(" "+_vm._s(_vm.computedMetadataText(_vm.model && _vm.model.clockOutForLunch && _vm.model.clockOutForLunch[0]))+" ")])]}}],null,true)})],1)],1),_c('v-divider',{staticClass:"my-5"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('custom-label',{attrs:{"item-field-name":"Clock-in Time After Lunch"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('TimePickerWrapperInputMenu',{attrs:{"value":_vm.clockInTimeAfterLunch,"outlined":"","error-messages":errors},on:{"input":function($event){return _vm.updateTimeValue($event, 'clockInAfterLunch', 0)}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"icon":"","text":"","elevation":"0"},on:{"click":function($event){return _vm.removeTimeValue(
                      'clockInAfterLunch',
                      _vm.model &&
                        _vm.model.clockInAfterLunch &&
                        _vm.model.clockInAfterLunch[0] &&
                        _vm.model.clockInAfterLunch[0]._id
                    )}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-minus")])],1)]},proxy:true}],null,true)}),_c('small',[_vm._v(" "+_vm._s(_vm.computedMetadataText( _vm.model && _vm.model.clockInAfterLunch && _vm.model.clockInAfterLunch[0] ))+" ")])]}}],null,true)})],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }