<template>
  <div class="d-flex align-baseline">
    <div>{{ get(store, 'name', '-') }}</div>
    <v-btn v-show="store" class="ml-1" small @click.stop="goToStore" icon>
      <v-icon small color="primary"> mdi-open-in-new</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { getOid } from '@/services/utils';
import { get } from 'lodash';

export default {
  name: 'StoreNameTag',
  props: {
    store: null,
  },
  data() {
    return {};
  },
  methods: {
    get,
    goToStore() {
      //window.open(window.location.origin + '/admin/profiles/' + getOid(this.profile));
      let id = getOid(this.store);

      const router = this.$router.resolve({
        name: 'store',
        params: { id },
      });
      window.open(router.href, `store-${id}`);
    },
  },
};
</script>
