<template>
  <v-card v-if="!loading">
    <v-card-title class="font-weight-bold">Transaction</v-card-title>
    <v-card-title>
      <v-row>
        <v-col :cols="12" :md="6">
          <template v-if="transaction && transaction.claimCreatedAt">
            {{ transaction.claimCreatedAt | moment('YYYY/MM/DD hh:mm:ss') }}
          </template>
          <template v-else-if="transaction && transaction.createdAt">
            {{ transaction.createdAt | moment('YYYY/MM/DD hh:mm:ss') }}
          </template>
          <template v-else> - </template>
        </v-col>
        <v-col :cols="12" :md="6" class="d-flex">
          Store:
          <StoreNameTag
            class="ml-1"
            v-if="transaction && transaction.store"
            :store="transaction && transaction.store"
          />
        </v-col>
        <v-col :cols="12" :md="6" class="text-capitalize">
          Type: {{ transaction && transaction.transactionType }}
        </v-col>
        <v-col
          v-if="(transaction && transaction.transactionType) === POSTransactionType.expense"
          :cols="12"
          :md="6"
          >Expense Type: {{ transaction && transaction.expenseType }}</v-col
        >
        <v-col :cols="12" :md="6">Amount: {{ currency }} {{ amount | toThousandFilter }}</v-col>
        <v-col :cols="12" :md="6">Remarks: {{ (transaction && transaction.remarks) || '-' }}</v-col>
        <v-col :cols="12" :md="6"> Status: {{ transaction && transaction.status | status }} </v-col>
        <v-col :cols="12" :md="6" class="d-flex">
          Created By:
          <ProfileNameTag
            class="ml-1"
            v-if="transaction && transaction.createdBy"
            :profile="transaction.createdBy"
          />
        </v-col>
      </v-row>
      <v-row style="max-width: 100%">
        <v-col :cols="12">Related Documents (Images)</v-col>
        <v-col :cols="12">
          <template
            v-if="
              transaction &&
              transaction.details &&
              transaction.details.attachments &&
              transaction.details.attachments.length
            "
          >
            <v-carousel>
              <v-carousel-item
                v-for="(image, i) in transaction &&
                transaction.details &&
                transaction.details.attachments"
                :key="i"
                :src="attnThumbHref(image, 'hd')"
                contain
              />
            </v-carousel>
          </template>
          <template v-else> <small>(No images)</small> </template>
        </v-col>
      </v-row>
    </v-card-title>
  </v-card>
</template>

<script>
import moment from 'moment';
import ProfileNameTag from '@/components/ProfileNameTag.vue';
import StoreNameTag from '@/components/StoreNameTag.vue';
import { POSTransactionType } from '@/data/constants.js';
import { attnThumbHref } from '@/services/filters';

export default {
  name: 'TransactionSummary',
  components: { ProfileNameTag, StoreNameTag },
  props: {
    transaction: {},
    loading: true,
  },
  data() {
    return {
      POSTransactionType,
    };
  },
  computed: {
    currency() {
      const currency = this.transaction?.amount?.currency?.toUpperCase();
      return `${currency}$`;
    },
    amount() {
      const value = this.transaction?.amount?.value?.$numberDecimal;
      return Math.abs(parseInt(value));
    },
  },
  methods: {
    moment,
    attnThumbHref,
  },
};
</script>
